.home {
  position: relative;
  top: 80px;
  height: calc(100vh - 80px);
}
.home::before {
  position: absolute;
  content: "";
  border-radius: 10px 10px 0 0;
  width: calc(50% - 4rem);
  height: 100%;
  top: 0;
  right: 0;
  z-index: 0;
  background-color: var(--Pr-color);
}
.home.ar::before {
  left: 0;
}
.home .home_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90%;
}

.home .home_body .home_b_l {
  flex: 0.6;
}
.home .home_body .home_b_r {
  flex: 0.7;
}
.home .home_body .home_b_r h1 {
  position: relative;
  font-size: 50px;
  margin-bottom: 20px;
}
.home .home_body .home_b_r p {
  margin-bottom: 25px;
  font-weight: 400;
}
.home .home_body .home_b_r .learn_more {
  background-color: var(--Pr-color);
  padding: 12px 20px;
  border-radius: 10px;
  color: var(--Sec-color);
  cursor: pointer;
}

.home .home_body .home_b_r h1::before {
  position: absolute;
  content: "";
  width: 50px;
  height: 3px;
  background-color: var(--Pr-color);
  bottom: -5px;
  animation: move_from_right_to_left 3s ease-in-out infinite;
  animation-fill-mode: forwards;
}
@keyframes move_from_right_to_left {
  0% {
    transform: translateX(405px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(405px);
  }
}
@media (max-width: 767px) {
  @keyframes move_from_right_to_left {
    0% {
      transform: translateX(250px);
    }
    50% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(250px);
    }
  }
}

@media (max-width: 767px) {
  .home {
    height: auto;
    /* height: 100vh; */
    /* min-height: calc(100vh - 80px); */
  }
  .section_container {
    max-width: 100%;
    padding: 2rem;
  }
  .home .home_body {
    flex-direction: column;
    /* height: 100vh; */
  }
  .home .home_body .home_b_r .learn_more {
    margin-bottom: 20px;
  }
  .home::before {
    height: 50vh;
    margin: auto;
    width: 95%;
    bottom: 0;
    right: 0;
    transform: translate(-2.5%, 25%);
    display: none;
  }
  .home.ar::before {
    transform: translate(0.5%, 15%);
  }
  .home.ar .home_body .home_b_r h1 {
    font-size: 40px;
  }
  .home.ar .home_body .home_b_l {
    flex: 0.7 !important;
  }
}

.home .home_footer {
  position: absolute;
  content: "";
  z-index: 1;
  color: var(--Pr-color);
  background-color: transparent;
  width: 85%;
  bottom: -80px;
  left: 50%;
  height: 160px;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0 0px 5px 0 rgba(211, 4, 30, 0.8); */
}

.home .home_footer .item_box {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 0 5px;
  border: 1px solid var(--Pr-color);
  background-color: var(--Sec-color);
  cursor: pointer;
}
.home .home_footer .item_box a {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.home .home_footer .item_box img {
  width: 70%;
  object-fit: contain;
}
@media (max-width: 767px) {
  .home .home_footer {
    position: relative;
    background-color: transparent;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    bottom: -100px;
    display: none;
  }
  .home .home_footer .item_box {
    width: 10%;
    margin: 0;
    background-color: var(--Sec-color);
    text-align: center;
  }

  .home .home_footer .item_box img {
    width: 100%;
  }
  .home .home_body .home_b_r {
    flex: unset;
    margin-bottom: 20px;
  }
  .home .home_body .home_b_l {
    flex: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
.home .home_footer .item_box p {
  font-size: 16px;
  color: var(--Tre-color);
}
.home .home_footer .item_box:last-child {
  margin-right: 0;
}

.swiper {
  margin: auto;
  width: 420px;
  right: -50px;
  /* height: 320px; */
}
@media (max-width: 767px) {
  .swiper {
    width: 350px;
    right: 0;
    top: 0;
    /* transform: translate(-50%, -50%); */
    /* padding: 10px !important; */
    border-radius: 12px !important;
  }
  .swiper-slide .item {
    padding: 15px 10px;
    border-radius: 12px !important;
  }
  .swiper-slide h2 {
    font-size: 20px;
  }
}
.swiper-3d {
  background-color: var(--Pr-color) !important;
  height: 500px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--Pr-color);
}

.swiper-slide h2 {
  background-color: var(--Pr-color);
}
.swiper-slide .item {
  text-align: center;
  background-color: var(--Pr-color);
  position: relative;
}
.swiper-slide .item::befored {
  position: absolute;
  content: "";
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 50%;
  height: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.7);
  /* filter: blur(50px); */
  /* box-shadow: 0 0 5px var(--Tre-color); */
  /* background-color: var(--Tre-color); */
}
.swiper-slide .item .item_block {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  background-color: var(--Pr-color);
}

/* From Uiverse.io by nikk7007 */
.swiper-slide .item .item_block .btn {
  --color: var(--Tre-color);
  --color2: var(--Pr-color);
  padding: 0.8em 1.75em;
  background-color: transparent;
  border-radius: 6px;
  border: 0.3px solid var(--color);
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--color);
}

.swiper-slide .item .item_block .btn::after,
.swiper-slide .item .item_block .btn::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  transform: skew(90deg) translate(-50%, -50%);
  position: absolute;
  inset: 50%;
  left: 25%;
  z-index: -1;
  transition: 0.5s ease-out;
  background-color: var(--color);
}

.swiper-slide .item .item_block .btn::before {
  top: -50%;
  left: -25%;
  transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
}

.swiper-slide .item .item_block .btn:hover::before {
  transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
}

.swiper-slide .item .item_block .btn:hover::after {
  transform: skew(45deg) translate(-50%, -50%);
}

.swiper-slide .item .item_block .btn:hover {
  color: var(--color2);
}

.swiper-slide .item .item_block .btn:active {
  filter: brightness(0.7);
  transform: scale(0.98);
}
