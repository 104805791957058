.Container_Nav {
  width: 100%;
  box-shadow: 0 0 5px -2px var(--Pr-color);
}
.NavBarDetails {
  height: 80px;
  padding: 5px 50px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.NavBarDetails .logo {
  flex: 0.4;
}

.NavBarDetails .logo img {
  width: 25%;
}

.NavBarDetails .Details {
  position: relative;
  top: -5px;
  font-size: 20px;
}
.NavBarDetails .GoToBack {
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Sec-color);
  background-color: var(--Pr-color);
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;
  animation: move-from-right-to-left 1s ease-in-out infinite;
  animation-fill-mode: forwards;
}
@keyframes move-from-right-to-left {
  0% {
    transform: translateX(20px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(20px);
  }
}
.NavBarDetails .Details {
  flex: 0.4;
}
@media (max-width: 767px) {
  .NavBarDetails {
    width: 100%;
    padding: 5px 30px;
    align-items: center;
  }
  .NavBarDetails .logo {
    flex: 0.5;
  }
  .NavBarDetails .logo img {
    width: 60%;
  }
  .NavBarDetails .GoToBack {
    font-size: 20px;
    position: relative;
    top: -5px;
  }
}
