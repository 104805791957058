.contact {
  position: relative;
  top: 160px;
  /* height: 50vh; */
}
@media(max-width:767px){
  .contact{
    top: 0;
  }
}
.contact_body {
  margin-top: 40px;
  height: 100%;
  display: flex;
}
.contact_b_l {
  flex: 0.5;
}
.contact_b_r {
  flex: 0.5;
}
.contact_b_r input,
.contact_b_r textarea {
  background-color: transparent;
  border: 2px solid var(--Pr-color);
  padding: 10px 20px;
  border-radius: 12px;
  outline: none;
  font-size: 15px;
  resize: none;
  width: 100%;
}
.contact_b_r .child_group {
  display: flex;
  gap: 0 20px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .contact_body {
    margin-top: 30px;
    flex-direction: column-reverse;
  }
}
.contact_b_r .child_group button {
  background-color: var(--Pr-color);
  border: none;
  padding: 10px 30px;
  font-size: 18px;
  border-radius: 12px;
  color: var(--Sec-color);
  cursor: pointer;
}
.contact_body .contact_icon {
  padding: 10px 20px;
}
.contact_body .contact_icon li {
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.contact_body .contact_icon li i {
  font-size: 30px;
  margin-right: 15px;
  color: var(--Pr-color);
}
.contact_body .contact_icon li label {
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 500;
  text-transform: uppercase;
}
.contact_body .social_media {
  display: flex;
  align-items: center;
}

.contact_body .social_media a {
  margin-right: 20px;
}
.contact_body .social_media i {
  color: var(--Sec-color);
}
.Btn {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  position: relative;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s;
}

.svgContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  backdrop-filter: blur(4px);
  letter-spacing: 0.8px;
  border-radius: 10px;
  transition: all 0.3s;
  border: 1px solid rgba(156, 156, 156, 0.466);
}
.children1 {
  margin-left: 40px;
}
.children1 .BG {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  z-index: -1;
  border-radius: 9px;
  pointer-events: none;
  transition: all 0.3s;
}
.children2 .BG {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  z-index: -1;
  border-radius: 9px;
  pointer-events: none;
  transition: all 0.3s;
  background: #3b5998;
  background: -moz-linear-gradient(
    45deg,
    #2f55a4 0%,
    #445c8f 25%,
    #c4a667 50%,
    #5874af 75%,
    #374a72 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #2f55a4 0%,
    #445c8f 25%,
    #c4a667 50%,
    #5874af 75%,
    #374a72 100%
  );
  background: linear-gradient(
    45deg,
    #2f55a4 0%,
    #445c8f 25%,
    #c4a667 50%,
    #5874af 75%,
    #374a72 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2f55a4', endColorstr='#374a72',GradientType=1 );
}
.Btn:hover .BG {
  transform: rotate(35deg);
  transform-origin: bottom;
}

.Btn:hover .svgContainer {
  background-color: rgba(156, 156, 156, 0.466);
}
