.products {
  position: relative;
  top: 160px;
  padding: 2rem !important;
}
.products_body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0 25px;
}
.products_p_l {
  flex: 0.2;
  height: 460px;
  background-color: #cccccc24;
  padding: 10px 15px;
  border-radius: 10px;
}
.products_p_l h3 {
  font-size: 25px;
  margin: 12px 0;
}
.products_p_l .items_cat {
  height: 85%;
  overflow: scroll;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: var(--Pr-color);
  border-radius: 10px;
}
.products_p_l .item_cat {
  padding: 15px 20px;
  border-radius: 12px;
  width: 100%;
  background-color: var(--Sec-color);
  margin: 10px 0;
  transition: all 0.3s ease-in-out;
  border: none;
  cursor: pointer;
  font-size: 18px;
  letter-spacing: 1px;
}
.products_p_l .item_cat:hover {
  border: 2px dashed var(--Pr-color);
}
.products_p_l .item_cat.active {
  background-color: var(--Pr-color);
  color: var(--Sec-color);
  border: none;
}
.products_p_r {
  flex: 0.8;
}
.products_p_r h3 {
  font-size: 25px;
  letter-spacing: 5px;
  padding: 10px 15px 0;
}
.products_p_r .det_pros {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
.products_p_r .det_pro {
  /* padding: 10px 20px; */
  /* cursor: pointer; */
  border-radius: 12px;
  margin: 14px;
  background-color: #cccccc24;
  border: 1px solid var(--Pr-color);
}
.products_p_r .det_pro img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 12px 12px 0 0;
  cursor: pointer;
}
.products_p_r .det_pro h3 {
  text-align: center;
}
.products_p_r .det_pro .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.products_p_r .det_pro .content p {
  font-size: 13px;
  letter-spacing: 2px;
}

.products_p_r .det_pro .content .button {
  --width: 100px;
  --height: 35px;
  --tooltip-height: 35px;
  --tooltip-width: 90px;
  --gap-between-tooltip-to-button: 18px;
  --button-color: var(--Pr-color);
  width: var(--width);
  height: var(--height);
  background: var(--button-color);
  position: relative;
  text-align: center;
  border-radius: 0.45em;
  font-family: "Arial";
  transition: background 0.3s;
  cursor: pointer;
}

.products_p_r .det_pro .content .button::after,
.products_p_r .det_pro .content .button::before {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

.products_p_r .det_pro .content .text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.products_p_r .det_pro .content .button-wrapper,
.products_p_r .det_pro .content .text,
.products_p_r .det_pro .content .icon {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  color: #fff;
}

.text {
  top: 0;
}

.products_p_r .det_pro .content .text,
.products_p_r .det_pro .content .icon {
  transition: top 0.5s;
}

.products_p_r .det_pro .content .icon {
  color: #fff;
  top: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.products_p_r .det_pro .content .icon svg {
  width: 24px;
  height: 24px;
}

.products_p_r .det_pro .content .button:hover {
  background: var(--Pr-color);
}

.products_p_r .det_pro .content .button:hover .text {
  top: -100%;
}

.products_p_r .det_pro .content .button:hover .icon {
  top: 0;
}

.products_p_r .det_pro .content .button:hover:before,
.products_p_r .det_pro .content .button:hover:after {
  opacity: 1;
  visibility: visible;
}

.products_p_r .det_pro .content .button:hover:after {
  bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) - 20px);
}

.products_p_r .det_pro .content .button:hover:before {
  bottom: calc(var(--height) + var(--gap-between-tooltip-to-button));
}
@media (max-width: 767px) {
  .products {
    top: 0;
    /* top: 280px; */
  }
  .products_p_l {
    width: 100%;
    flex: auto;
    height: 350px !important;
  }
  .products_p_l .items_cat {
    height: 85%;
    scrollbar-width: 5px !important;
  }
  .products_p_r {
    width: 100%;
  }
  .products_p_r .det_pros {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .products_p_r .det_pro img {
    height: 250px;
  }
  .products_body {
    flex-direction: column;
  }
}
