.service {
  position: relative;
  top: 160px;
}
@media (max-width: 760px) {
  .service .section_header h2 {
    font-size: 3.5rem !important;
  }
  .service {
    margin-top: 40px;
    top: 0px;
  }
}
.service_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service_b_l {
  width: 45%;
  position: relative;
  display: grid;
  gap: 5px 10px;
  grid-template-columns: 50% 50%;
}
.service_b_l::before {
  position: absolute;
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Sec-color);
  font-size: 18px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--Pr-color);
  border: 5px solid var(--Sec-color);
  width: 200px;
  height: 200px;
  object-fit: contain;
  border-radius: 50%;
}
.service_b_l img {
  width: 100%;
  border-radius: 8px;
}
.service_b_r {
  width: 50%;
}

.service_b_r h3 {
  font-size: 35px;
  letter-spacing: 12px;
}
.service_b_r h3 span {
  font-size: 50px;
  color: var(--Pr-color);
}

.service_b_r p {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 30px;
}

.service_b_r .learn_more {
  background-color: var(--Pr-color);
  padding: 12px 20px;
  border-radius: 10px;
  color: var(--Sec-color);
  cursor: pointer;
}
@media (max-width: 767px) {
  .service_body {
    flex-direction: column-reverse;
  }
  .service_b_l::before {
    width: 120px;
    height: 120px;
    font-size: 15px;
  }
  .service_b_r h3 {
    font-size: 20px;
    white-space: nowrap;
  }
  .service_b_l {
    margin-top: 40px;
  }
  .service_b_r,
  .service_b_l {
    width: 100%;
  }
}
