@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins";
  /* background-color: var(--Sec-color); */
}
.ar {
  flex-direction: row-reverse;
}
.arLang {
  font-family: "Cairo";
  font-size: 20px;
}
.section_container {
  max-width: var(--max-width);
  margin: auto;
  padding: 2rem 4rem;
}
.section_subheader {
  font-size: 12px;
  margin-bottom: 1rem;
  font-weight: 600;
  color: var(--Tre-color);
  opacity: 0.6;
  letter-spacing: 2px;
  text-align: center;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
.section_header {
  font-size: 2.5rem;
  font-weight: 800;
  color: var(--Pr-color);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section_header img {
  width: 8%;
  animation: move-from 3s ease-in-out infinite;
  animation-fill-mode: forwards;
}

@media (max-width: 767px) {
  .section_header h2 {
    font-size: 3.5rem !important;
  }
  .section_header img {
    width: 20%;
  }
}

@keyframes move-from {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(0);
  }
}
