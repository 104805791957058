.footer {
  position: relative;
  top: 120px;
  max-width: 100%;
  background-color: var(--Pr-color);
  text-align: center;
  letter-spacing: 5px;
  color: var(--Sec-color);
}
@media (max-width: 767px) {
  .footer {
    top: 0;
    font-size: 12px;
  }
}
