.ProductDetails {
  height: calc(100vh - 80px);
  margin: auto;
  position: relative;
}
._body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin: auto;
  gap: 0 50px;
}
@media (max-width: 767px) {
  ._body {
    flex-direction: column;
    align-items: center;
  }
}
._body ._p_l {
  flex: 0.4;
  position: relative;
}
._body ._p_r {
  flex: 0.6;
}
._body ._p_l img {
  width: 450px;
  height: 450px;
  object-fit: cover;
  border-radius: 12px;
}
._body ._p_l::before {
  position: absolute;
  content: "";
  top: 10px;
  left: -15px;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 10px;
  background-color: var(--Pr-color);
}
@media (max-width: 767px) {
  ._body ._p_l img {
    width: 350px;
    height: 350px;
  }
}
._p_r .title {
  font-size: 40px;
  /* text-align: center; */
  color: var(--Pr-color);
  letter-spacing: 5px;
}
._p_r .pragraph {
  font-size: 25px;
  color: #6e6e6e;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 2px solid var(--Pr-color);
}
._p_r .information {
  position: relative;
  margin-top: 20px;
  padding: 10px 20px;
  border: 2px solid var(--Pr-color);
  border-radius: 10px;
  height: 100%;
}

._p_r .information::before {
  position: absolute;
  content: "Information";
  top: -15px;
  z-index: 100;
  padding: 0 10px;
  background-color: var(--Sec-color);
  color: var(--Pr-color);
  left: 20px;
  font-size: 14px;
}
._p_r .clices {
  width: 100%;
  text-align: center;
  padding: 15px 0;
  display: flex;
}
._p_r .clices button {
  padding: 2px 10px;
  margin: 0 20px;
  font-size: 20px;
  background-color: var(--Pr-color);
  border: none;
  color: var(--Sec-color);
  border-radius: 15px;
  cursor: pointer;
}
._p_r .clices label {
  font-size: 18px;
}

._p_r .button {
  --width: 100px;
  --height: 35px;
  --tooltip-height: 35px;
  --tooltip-width: 90px;
  --gap-between-tooltip-to-button: 18px;
  --button-color: var(--Pr-color);
  width: var(--width);
  height: var(--height);
  background: var(--button-color);
  position: relative;
  text-align: center;
  border-radius: 0.45em;
  font-family: "Arial";
  transition: background 0.3s;
  cursor: pointer;
}

._p_r .button::after,
._p_r .button::before {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

._p_r .text {
  display: flex;
  align-items: center;
  justify-content: center;
}

._p_r .button-wrapper,
._p_r .text,
._p_r .icon {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  color: #fff;
}

.text {
  top: 0;
}

._p_r .text,
._p_r .icon {
  transition: top 0.5s;
}

._p_r .icon {
  color: #fff;
  top: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

._p_r .icon svg {
  width: 24px;
  height: 24px;
}

._p_r .button:hover {
  background: var(--Pr-color);
}

._p_r .button:hover .text {
  top: -100%;
}

._p_r .button:hover .icon {
  top: 0;
}

._p_r .button:hover:before,
._p_r .button:hover:after {
  opacity: 1;
  visibility: visible;
}

._p_r .button:hover:after {
  bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) - 20px);
}

._p_r .button:hover:before {
  bottom: calc(var(--height) + var(--gap-between-tooltip-to-button));
}
