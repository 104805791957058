.nav {
  position: fixed;
  top: 0;
  z-index: 100;
  height: 80px;
  /* overflow: hidden; */
  background-color: var(--Sec-color);
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.5);
}
nav {
  width: 90%;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.nav .logo_menu {
  flex: 0.6;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.nav .logo {
  width: 20%;
}
.nav .logo img {
  width: 100%;
}
.nav .menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0.8;
  height: 100%;
  position: relative;
  top: -5px;
  transform: translate(0);
  transition: 0.3s ease-in-out;
}

.nav .menu li {
  font-size: 16px;
  color: var(--Tre-color);
  cursor: pointer;
  position: relative;
  border: none;
  background: none;
  font-family: "Roboto";
  text-transform: uppercase;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;
}
.nav .menu li:focus,
.nav .menu li:hover {
  color: var(--Pr-color);
}
.nav .menu li:focus:after,
.nav .menu li:hover:after {
  width: 100%;
  left: 0%;
}
.nav .menu li:after {
  content: "";
  pointer-events: none;
  bottom: -2px;
  left: 50%;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: var(--Pr-color);
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: width, left;
}
.nav .menu .nav-close-btn,
.nav .menu_icon_right {
  display: none;
}
.nav .search_store {
  flex: 0.2;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.nav .search_store i {
  position: relative;
  top: -5px;
  cursor: pointer;
  font-size: 25px;
}

.nav .search_store i:hover {
  color: var(--Pr-color);
}
.nav .search_store select {
  padding: 6px 12px;
  background-color: transparent;
  border: 2px solid var(--Pr-color);
  font-size: 14px;
  border-radius: 12px;
}
.nav .search_store select option {
  background-color: var(--Pr-color);
  color: var(--Sec-color);
  font-size: 16px;
  text-align: center;
  font-family: "Cairo";
  font-weight: 600;
}
.nav .search_store select option:hover {
  background-color: var(--Pr-color);
}
@media (max-width: 767px) {
  nav {
    width: 95%;
    margin: 0;
  }
  .nav .menu .nav-close-btn {
    /* display: inline; */
  }
  .nav .menu_icon_right {
    /* position: relative; */
    top: 5px;
    display: inline;
    font-size: 30px;
    cursor: pointer;
  }
  nav.ar .menu_icon {
    position: relative;
    top: -5px;
    transform: rotate(180deg);
    margin-left: 20px;
  }
  .nav .menu {
    display: none;
    flex-direction: column;
    /* visibility: collapse; */
    transform: translateY(0px);
    /* opacity: 0; */
    transition: all 0.3s ease;
  }
  .nav .menu.active {
    /* visibility: visible; */
    /* opacity: 1; */
    display: flex;
    position: absolute;
    top: 190px;
    right: 50%;
    width: 100%;
    height: max-content;
    transform: translate(53%, -50%);
    background-color: var(--Tre-color);
    color: var(--Sec-color);
    padding: 20px 0;
    border-radius: 10px;
  }
  nav.ar .menu.active {
    top: 220px;
  }
  .nav .menu.active li {
    padding: 10px 0;
    color: var(--Sec-color);
  }
  .nav .logo_menu {
    flex: 0.5;
  }
  .nav .logo {
    width: 80%;
  }
}
